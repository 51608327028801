import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Inview from 'components/Inview'

SwiperCore.use([Autoplay])

export default function SlideshowMoreItemsBlock({
  content,
  collapsePadding = true,
}) {
  const { title, slides } = content
  const padClass = collapsePadding
    ? 'pad-v-standard'
    : 'pad-v-standard no-collapse'

  return (
    <section className={`slideshow-more-items-block ${padClass}`}>
      <div className='container'>
        <Inview className='fade-in up'>
          <h3>{title}</h3>
        </Inview>
        <SwiperComponent slides={slides} />
      </div>
    </section>
  )
}

const SwiperComponent = ({ slides }) => {
  return (
    <div className='swiper-component'>
      <Inview className='swiper-images fade-in up'>
        <Swiper
          loop={true}
          loopAdditionalSlides={1}
          speed={600}
          autoplay={{
            delay: 5000,
          }}
          spaceBetween={12}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            601: {
              slidesPerView: 1.5,
            },
            1025: {
              slidesPerView: 2.5,
            },
          }}
        >
          {slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <Slide content={slide} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Inview>
    </div>
  )
}

const Slide = ({ content }) => {
  const { image, date, title, link } = content
  return (
    <LinkWrap to={link}>
      <div className='image'>
        <div className='inner'>
          <ImageWrap image={image} />
        </div>
      </div>
      <div className='text'>
        <h3>{date}</h3>
        <h2 className='smaller'>{title}</h2>
      </div>
    </LinkWrap>
  )
}
