import React from 'react'
import ImageWrap from 'components/ImageWrap'
import ButtonLink from 'components/ButtonLink'
import Inview from 'components/Inview'
import { useLocalization } from 'gatsby-theme-i18n'

export default function BannerBlock({
  content,
  hasIntroBlock,
  mobileBgBeige,
  isHome,
}) {
  const { locale } = useLocalization()
  const { image, pretitle, title, excerpt, link, alignH, alignV, textColor } =
    content
  const hasIntroBlockClass = hasIntroBlock ? 'has-intro-block' : ''
  const mobileBgBeigeClass = mobileBgBeige ? 'mobile-bg-beige' : ''
  const isHomeClass = isHome ? 'is-home' : ''
  return (
    <section className={`banner-block ${mobileBgBeigeClass}`}>
      <Inview className={`inner ${hasIntroBlockClass}`}>
        <div className='image fade-in stagger-200'>
          <ImageWrap image={image} />
        </div>
        <div
          className={`text-wrap fade-in stagger-500 
          ${textColor === 'white' ? 'text-color-inverse' : ''}
          `}
        >
          <div
            className={`inner container align-h-${alignH} align-v-${alignV}`}
          >
            <div className={`text ${isHomeClass} locale-${locale}`}>
              {pretitle && (
                <h3 dangerouslySetInnerHTML={{ __html: pretitle }} />
              )}
              {title && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
              {excerpt && <p dangerouslySetInnerHTML={{ __html: excerpt }} />}
              {link && <ButtonLink to={link} />}
            </div>
          </div>
        </div>
      </Inview>
    </section>
  )
}
