import React from 'react'
import { graphql } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import { tr } from 'content/strings'
import BannerBlock from 'components/blocks/BannerBlock'
import NewsIntroBlock from 'components/blocks/NewsIntroBlock'
import PostGridBlock from 'components/blocks/PostGridBlock'
import SlideshowMoreItemsBlock from 'components/blocks/SlideshowMoreItemsBlock'

export default function AwardsPage({ data }) {
  const { locale } = useLocalization()

  const awardsPosts = data.allStrapiAwardsPost.nodes
  const firstPost = awardsPosts.slice(0, 1)[0]
  const nextAwardsPosts = awardsPosts.slice(1, 5)
  const moreAwardsPosts = awardsPosts.slice(5)
  const customSlideshowBlock = {
    title: tr('archive', locale),
    slides: moreAwardsPosts.map((post) => ({
      image: post.bannerImageOnlyBlock?.image,
      date: post.date,
      title: post.title,
      link: `/awards/${post.slug}/`,
    })),
  }
  const customBanner = {
    image: firstPost.bannerImageOnlyBlock?.image,
    textColor: firstPost.bannerImageOnlyBlock?.textColor,
  }

  const customIntroBlock = {
    date: firstPost.date,
    title: firstPost.title,
    excerpt: firstPost.excerpt,
    link: `/awards/${firstPost.slug}/`,
  }

  return (
    <div>
      <div className='page page-awards'>
        <BannerBlock content={customBanner} hasIntroBlock={true} />
        <NewsIntroBlock content={customIntroBlock} borderBottom={false} />
        <PostGridBlock posts={nextAwardsPosts} />
        <SlideshowMoreItemsBlock
          content={customSlideshowBlock}
          collapsePadding={false}
        />
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query ($locale: String!) {
    strapiAwardsPage(locale: { eq: $locale }) {
      title
      seo {
        title
        description
      }
    }
    allStrapiAwardsPost(
      filter: { locale: { eq: $locale } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        excerpt
        slug
        date
        bannerImageOnlyBlock {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          textColor
        }
      }
    }
  }
`
