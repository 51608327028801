import React from 'react'
import ButtonLink from 'components/ButtonLink'

export default function ({ content, borderBottom = true }) {
  const { date, title, excerpt, link } = content
  return (
    <div className='news-intro-block container'>
      <div className='block'>
        <h3>{date}</h3>
        <h1>{title}</h1>
        <div className='border' />
        <p>{excerpt}</p>
        {link && <ButtonLink to={link} />}
        {borderBottom && <div className='border-bottom' />}
      </div>
    </div>
  )
}
